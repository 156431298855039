import React from "react";
import styled from "styled-components";
import Paragraph from "../components/blog/Paragraph";
import BlogTitle from "../components/blog/BlogTitle";
import ImageWithCaption from "../components/blog/ImageWithCaption";
import BlogHeading2 from "../components/blog/BlogHeading2";
import InternalLink from "../components/blog/InternalLink";
import ExternalLink from "../components/blog/ExternalLink";
import BlockQuote from "../components/blog/BlockQuote";
import UnorderedList from "../components/blog/UnorderedList";
import UnorderedListItem from "../components/blog/UnorderedListItem";
import {StaticImage} from "gatsby-plugin-image";
import SEO from "../components/SEO";
import BlogHeading3 from "../components/blog/BlogHeading3";
import { withPrefix } from "gatsby";

const BlogPostWrapper = styled.main`
  padding: var(--main-padding-mob);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledMainContent = styled.div`  
  max-width: 750px; 
`;

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const BlogPost = ({location}) =>{
    return <BlogPostWrapper> <StyledMainContent>
        <SEO
            title="Diabetic Shopping List: Best And Worst Foods"
            description="Discover the secrets to maintaining a healthy body with the Blood Type O Diet in our guide that provides a shopping list and tips for weight loss."
            location={location}
            pageType="article"
        />

        <HeroSection>
            <BlogTitle>
                What Is The Blood Type O Diet? Guide + Shopping List
            </BlogTitle>
            <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/blood-type-O-shopping-list.jpg" alt="What Is The Blood Type O Diet?"/>
                <em>Photo by <ExternalLink to="https://unsplash.com/@ca_creative?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">CA Creative</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/seafood?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
            </ImageWithCaption>
        </HeroSection>
        <Paragraph>
            Having blood type O is considered special because you can donate blood to almost anyone.
        </Paragraph>
        <Paragraph>
            O- blood type is especially valuable as it is used in hospitals to give blood when a patient's blood type is unknown, helping save lives.
        </Paragraph>
        <Paragraph>
            Only <ExternalLink to ="https://www.redcrossblood.org/donate-blood/blood-types/o-blood-type.html">7%</ExternalLink> of the population is O-, while it is almost completely opposite with O+: 38% of the global population is O+, making it the most common blood type.
        </Paragraph>
        <Paragraph>
            So what should blood type O individuals eat in order to maintain the health of their blood and their bodies overall?
        </Paragraph>
        <Paragraph>
            According to the <ExternalLink to ="https://www.dadamo.com/">blood type diet</ExternalLink>, there are specific food groups you should focus on and/or avoid according to what blood type you have.
        </Paragraph>
        <Paragraph>
            Key takeaways from this article:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>The blood type O shopping list is focused on vegetables, fruit, meat and seafood, with a dose of healthy fats.</UnorderedListItem>
            <UnorderedListItem>The top food groups to avoid on a blood type O diet are grains, dairy, processed foods, and beans and lentils.</UnorderedListItem>
            <UnorderedListItem>Blood type O diet can be beneficial for weight loss because it is low in carbs and high in protein. ⚠️However, it is a highly restrictive diet.</UnorderedListItem>
            <UnorderedListItem>The blood type diet has been debunked by science many times, and you should be aware that the concept of linking your diet with your blood type may not be rooted in medical facts.</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Blood type O food list
        </BlogHeading2>
        <BlogHeading3>
            Vegetables
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Broccoli</UnorderedListItem>
            <UnorderedListItem>Garlic</UnorderedListItem>
            <UnorderedListItem>Onion</UnorderedListItem>
            <UnorderedListItem>Lettuce</UnorderedListItem>
            <UnorderedListItem>Spinach</UnorderedListItem>
            <UnorderedListItem>Kale</UnorderedListItem>
            <UnorderedListItem>Pepper</UnorderedListItem>
            <UnorderedListItem>Sweet potatoes</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Fruit
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Plums</UnorderedListItem>
            <UnorderedListItem>Figs</UnorderedListItem>
            <UnorderedListItem>Prunes</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Meat
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Lamb</UnorderedListItem>
            <UnorderedListItem>Beef</UnorderedListItem>
            <UnorderedListItem>Buffalo</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Seafood
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Salmon</UnorderedListItem>
            <UnorderedListItem>Sardine</UnorderedListItem>
            <UnorderedListItem>Herring</UnorderedListItem>
            <UnorderedListItem>Mackerel</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            Healthy fats
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Olive oil</UnorderedListItem>
            <UnorderedListItem>Flaxseed oil</UnorderedListItem>
        </UnorderedList>
        <BlockQuote>
            <b>PRO TIP:</b> Add these items to a <InternalLink to="/#download-section">free shopping list app</InternalLink> to start your blood type O diet ASAP!
        </BlockQuote>
        <BlogHeading2>
            Blood type O shopping list printable
        </BlogHeading2>
        <Paragraph>
        <ExternalLink to = {withPrefix("Blood Type O Shopping List.pdf")}>Click here to download the blood type O shopping list PDF.</ExternalLink>
        </Paragraph>
        <BlogHeading2>
            How this food list helps lose weight
        </BlogHeading2>
        <Paragraph>
            The general diet recommendations for blood type O are similar to a high protein-low carb diet such as paleo.
        </Paragraph>
        <Paragraph>
            Because the amount (and type) of carbs you eat is limited, you are bound to lose weight when following a blood type O meal plan.
        </Paragraph>
        <BlogHeading3>
            Vegetables
        </BlogHeading3>
        <Paragraph>
            Veggies are a particularly healthy choice for any healthy diet. They also aid in weight loss because they are high in fiber and low in carbs.
        </Paragraph>
        <Paragraph>
            <ExternalLink to ="https://pubmed.ncbi.nlm.nih.gov/29506604/">Studies have shown</ExternalLink> that eating more vegetables is associated with lower signs of obesity and metabolic syndrome.
        </Paragraph>
        <Paragraph>
            Vegetables such as broccoli, kale, and spinach are known for their weight loss benefits. 🥬
        </Paragraph>
        <BlogHeading3>
            Fruit
        </BlogHeading3>
        <Paragraph>
            Fruit should be eaten only in moderation on the blood type O diet, focusing on fruit that is low in sugar. 
        </Paragraph>
        <Paragraph>
            Remaining low on sugar keeps your insulin metabolism in check which aids weight loss.
        </Paragraph>
        <BlogHeading3>
            Meat
        </BlogHeading3>
        <Paragraph>
            Meat will be your number one source of satiety on the blood type O diet. It has no carbs and is very high in protein, making it a great weight loss food. 
        </Paragraph>
        <Paragraph>
            <ExternalLink to = "https://fdc.nal.usda.gov/fdc-app.html#/food-details/168744/nutrients">Beef</ExternalLink>, for example, is also a good source of iron, zinc and vitamin B12.
        </Paragraph>
        <BlogHeading3>
            Seafood
        </BlogHeading3>
        <Paragraph>
            Similar to meat, seafood is also high in protein.
        </Paragraph>
        <Paragraph>
            Some seafood, like salmon, is especially good for weight loss. Salmon is high in <ExternalLink to = "https://pubmed.ncbi.nlm.nih.gov/29621669/">omega 3 fatty acids</ExternalLink>, which help reduce inflammation that has been linked to obesity. 
        </Paragraph>
        <Paragraph>
            Besides that, seafood also contains iodized salt, which improves the functioning of the thyroid.
        </Paragraph>
        <BlogHeading3>
            Healthy fats
        </BlogHeading3>
        <Paragraph>
            Healthy fats are good for you if you're looking to lose weight on a blood type O diet, since you won't be eating a lot of carbs. 
        </Paragraph>
        <Paragraph>
            <ExternalLink to = "https://pubmed.ncbi.nlm.nih.gov/30487558/">Olive oil</ExternalLink> is especially proven to be beneficial for weight loss.
        </Paragraph>
        <Paragraph>
            However, some healthy fats are not allowed, such as peanut butter, so make sure to avoid it.
        </Paragraph>
        <BlogHeading2>
            Top 5 food groups to avoid on a blood type O diet
        </BlogHeading2>
        <BlogHeading3>
            1. Dairy & Eggs
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Milk</UnorderedListItem>
            <UnorderedListItem>Buttermilk</UnorderedListItem>
            <UnorderedListItem>Cheese</UnorderedListItem>
            <UnorderedListItem>Kefir</UnorderedListItem>
            <UnorderedListItem>Yogurt</UnorderedListItem>
            <UnorderedListItem>Eggs</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            2. Grains
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Wheat flour</UnorderedListItem>
            <UnorderedListItem>Pasta</UnorderedListItem>
            <UnorderedListItem>Noodles</UnorderedListItem>
            <UnorderedListItem>Oatmeal</UnorderedListItem>
            <UnorderedListItem>Bread</UnorderedListItem>
        </UnorderedList>
        <BlogHeading3>
            3. Processed food
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Snacks (crackers, chips, etc.)</UnorderedListItem>
            <UnorderedListItem>Sweets (candy, doughnuts, ice cream, chocolate)</UnorderedListItem>
            <UnorderedListItem>Processed meat (hot dogs, sausages)</UnorderedListItem>
        </UnorderedList>
        <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/post-2023-3-three-large-cheeseburgers-with-relish.jpg" alt="Three large cheeseburgers with relish."/>
                <em>Photo by <ExternalLink to="https://unsplash.com/fr/@juniorreisfoto?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Junior REIS</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/processed-food?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
        </ImageWithCaption>
        <BlogHeading3>
            4. Beans and lentils
        </BlogHeading3>
        <UnorderedList>
            <UnorderedListItem>Black beans</UnorderedListItem>
            <UnorderedListItem>Lentils</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            5. Caffeinated drinks
        </BlogHeading2>
        <UnorderedList>
            <UnorderedListItem>Coffee (including decaf)</UnorderedListItem>
            <UnorderedListItem>Black tea</UnorderedListItem>
            <UnorderedListItem>Coke (including diet coke)</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            What is the blood type diet?
        </BlogHeading2>
        <Paragraph>
            As the name suggests, the blood type diet is an eating plan that requires you to eat and avoid certain foods according to your blood type.
        </Paragraph>
        <Paragraph>
            It was developed by a naturopathic physician Peter D'Adamo and gained popularity in the 1990s, especially with his book 'Eat Right 4 Your Type'.
        </Paragraph>
        <Paragraph>
            The idea behind it is to reduce the risk of heart and kidney disease by reducing blood clumping which is achieved by an adequate blood type diet.
        </Paragraph>
        <Paragraph>
            Unfortunately, there is no scientific proof that supports the efficacy of this diet.
        </Paragraph>
        <Paragraph>
            However people all around the world have tried it.
        </Paragraph>
        <Paragraph>
            The diet also encourages physical activity, and provides advice on the type of activity best suited for each of the blood types.
        </Paragraph>
        <Paragraph>
            In short, the blood type diets consist of:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem><b>Type A blood diet. </b>Vegetarian organic diet coupled with light exercises such as yoga and tai chi.</UnorderedListItem>
            <UnorderedListItem><b>Type B blood diet. </b>Balanced diet and exercise.</UnorderedListItem>
            <UnorderedListItem><b>Type AB blood diet. </b>Mainly vegan diet and a combination of A and B types of exercises.</UnorderedListItem>
            <UnorderedListItem><b>Type O blood diet. </b>Vigorous exercise and a grain-free dairy-free diet.</UnorderedListItem>
        </UnorderedList>
        <BlogHeading2>
            Is the blood type O diet healthy?
        </BlogHeading2>
        <Paragraph>
            The blood O type diet is similar to the paleo diet, which has been very popular among celebrities in recent years.
        </Paragraph>
        <Paragraph>
            (In case you're curious about the paleo diet, we've got you covered with a <InternalLink to = "/paleo-shopping-list/">paleo shopping list</InternalLink>.)
        </Paragraph>
        <Paragraph>
            Health benefits of the blood type O diet include the general benefits of reducing processed foods intake as well as the benefits of eating a low carb diet. This is good for aiding with multiple health problems such as cardiovascular disease and diabetes.
        </Paragraph>
        <Paragraph>
            Other benefits of the blood type O diet are:
        </Paragraph>
        <UnorderedList>
            <UnorderedListItem>Focusing on whole foods</UnorderedListItem>
            <UnorderedListItem>Encouraging exercise</UnorderedListItem>
            <UnorderedListItem>Encouraging mindful eating</UnorderedListItem>
            <UnorderedListItem>Minimizing the consumption of processed foods</UnorderedListItem>
        </UnorderedList>
        <Paragraph>
            ❌However, it should be noted that this is a highly restrictive food regimen for a diet that hasn't been scientifically proven. 
        </Paragraph>
        <Paragraph>
            Make sure you consult your doctor before embarking on such a severely limiting diet plan.
        </Paragraph>
        <ImageWithCaption>
                <StaticImage src="../assets/images/blogs/post-2023-3-woman-eating-mindfully.jpg" alt="Woman in a restaurant with a smile on her face eating mindfully."/>
                <em>Photo by <ExternalLink to="https://unsplash.com/@pablomerchanm?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Pablo Merchán Montes</ExternalLink> on <ExternalLink to="https://unsplash.com/s/photos/mindful-eating?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</ExternalLink></em>
        </ImageWithCaption>
        <BlogHeading2>
            Is the blood type O diet debunked?
        </BlogHeading2>
        <Paragraph>
            Although the blood type diet has been fairly popular in recent decades, science hasn't been so enthusiastic about it.
        </Paragraph>
        <Paragraph>
            Some <ExternalLink to = "https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0084749">studies have shown</ExternalLink> that, even though the blood type diet has favorable effects on the body, these effects aren't associated with a person's blood type. Rather, if anyone of any blood type was on the blood type O diet, they would all experience the same results.
        </Paragraph>
        <Paragraph>
            <ExternalLink to = "https://www.jandonline.org/article/S2212-2672(20)31197-7/fulltext">More studies</ExternalLink> have proven that common health problems of the Western world, such as cardiovascular disease, obesity, and poor sugar metabolism, improve with a <InternalLink to = "/plant-based-diet-shopping-list/">plant based diet</InternalLink> regardless of the blood type.
        </Paragraph>
        <Paragraph>
            Other <ExternalLink to = "https://academic.oup.com/ajcn/article/98/1/99/4578345">research</ExternalLink>  has debunked the blood type diet, suggesting that there are no health benefits at all related to blood type diets
        </Paragraph>
        <Paragraph>
            In case you decide to follow a blood type O diet, or any other highly restrictive meal plan, once again, make sure to consult a medical professional first.
        </Paragraph>
        <BlogHeading2>
            Conclusion
        </BlogHeading2>
        <Paragraph>
            Blood type O diet requires you to stay away from dairy, grains, and processed foods, while focusing on meat, seafood, and vegetables.
        </Paragraph>
        <Paragraph>
            Low carb diets such as this one are known to help weight loss, and following the blood type O diet is likely to help you combat extra weight.
        </Paragraph>
        <Paragraph>
            However, health benefits related to a specific diet tailored to your blood type haven't been scientifically proven.
        </Paragraph>
        <Paragraph>
            If you are planning to follow this diet, we hope that this blood type O diet shopping list will help you on your next trip to the grocery store. 
        </Paragraph>
        <Paragraph>
            Add it to this <InternalLink to = "/#download-section">shopping list app</InternalLink> so you can take it with you wherever you want to go shopping!
        </Paragraph>
        <Paragraph>
            P. S. If you follow a vegan or vegetarian diet, the blood type O diet will not be suitable. In that case, make sure to check out our <InternalLink to = "/vegan-pantry-staples/">vegan pantry staples list</InternalLink>.
        </Paragraph>


    </StyledMainContent>
    </BlogPostWrapper>
}

export default BlogPost;